<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">用户提现</h3>
    </div>
    <div class="box">
      <div class="top">
        <span style="font-size: 14px; margin-left: 10px">名称:</span>
        <el-input v-model="from.name" placeholder="请输入用户名"></el-input>
        <div class="time">
          <span class="orderTime">申请时间: </span>
          <el-date-picker
            v-model="TimeValue"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="left"
          >
          </el-date-picker>
          <span class="orderTime" style="margin-left: 20px">审核时间: </span>
          <el-date-picker
            v-model="checkTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="left"
          >
          </el-date-picker>
        </div>
        <br />
        <span style="font-size: 14px; margin-left: 10px">电话:</span>
        <el-input
          v-model="from.cellphone"
          placeholder="请输入手机号"
        ></el-input>
        <div style="display: inline; margin-right: 100px; padding-left: 10px">
          <span style="font-size: 14px; margin-right: 10px">门店状态:</span>
          <el-radio v-model="from.status" :label="2">通过</el-radio>
          <el-radio v-model="from.status" :label="3">驳回</el-radio>
          <el-radio v-model="from.status" :label="''">全部</el-radio>
        </div>

        <span
          class="addshop shou"
          style="
            margin-left: 20px;
            padding: 5px 15px;
            background-color: #0970cc;
          "
          @click="shaixuan"
          >筛选</span
        >
      </div>
      <span style="color: #000">提现列表:</span>
      <el-table
        :data="list"
        stripe
        border
        :header-row-style="{ height: '60px' }"
        :row-style="{ height: '30px' }"
        style="width: 90%"
      >
        <el-table-column prop="id" label="ID" width="80"> </el-table-column>
        <el-table-column prop="store_user.username" label="提现人">
        </el-table-column>
        <el-table-column prop="store_user.mobile" label="电话">
        </el-table-column>
        <el-table-column prop="change_money" label="提现金额">
        </el-table-column>
        <el-table-column label="申请时间">
          <div slot-scope="scope">
            {{ scope.row.createtime | datefilter }}
          </div>
        </el-table-column>
        <el-table-column label="类型">
          <div>提现</div>
        </el-table-column>
        <el-table-column label="审核">
          <div class="shenhe" slot-scope="scope">
            <span v-show="scope.row.status == 2">已通过</span>
            <span v-show="scope.row.status == 3">已驳回</span>
            <el-button
              type="primary"
              round
              class="shou"
              size="mini"
              v-show="scope.row.status == 1"
              @click="shenhe(scope.row.id, scope.row.store_user.username)"
              >待审核</el-button
            >
          </div>
        </el-table-column>
        <el-table-column prop="shenhezhuangtai" label="审核进度">
          <div slot-scope="scope" class="jindu">
            <el-tag v-if="scope.row.status == 1" size="mini" type="info"
              >审核中</el-tag
            >
            <template v-else-if="scope.row.status == 2">
              <el-tag size="mini" effect="dark">已通过</el-tag>
              <span
                v-if="scope.row.pay_status == 1 || scope.row.pay_status == 3"
                class="shou dauan"
                @click="emitUser(scope.row.id)"
                >待打款</span
              >

              <el-tag
                v-if="scope.row.pay_status == 2"
                size="mini"
                type="success"
                effect="dark"
                >已打款</el-tag
              >
              <el-tag
                v-if="scope.row.pay_status == 3"
                size="mini"
                effect="dark"
                type="danger"
                >打款失败</el-tag
              >
            </template>
            <el-tag v-else size="mini" type="danger" effect="dark"
              >已驳回</el-tag
            >
          </div>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="crtChg"
        :current-page="from.page"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog title="提现申请" :visible.sync="dialogShenHeShow" width="18%">
      <p>
        是否同意<span style="color: #111">{{ TXname }}</span
        >的提现申请?
      </p>
      <div class="txbtn">
        <el-button type="success" @click="TXsuccess">通过</el-button>
        <el-button type="danger" @click="TXerror">驳回</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogShenHeShow = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { userCapital, userCheck, userRemit } from "@/api/api.js";
export default {
  components: {},
  name: "financeList",
  data() {
    return {
      from: {
        status: "", // 状态
        name: "", // 店主
        cellphone: "", // 电话
        start_time: "", //  开始时间
        end_time: "", //  结束时间
        page: 1, // 页码
        check_start_time: "",
        check_end_time: "",
      },
      TimeValue: null,
      checkTime: null,
      dialogShenHeShow: false,
      list: [],
      TXname: "",
      TXid: null,
      total: 10,
    };
  },
  watch: {
    checkTime: function (newVal, oldVal) {
      if (newVal != null) {
        this.from.check_start_time = this.$timeTo.time4(this.checkTime[0]);
        this.from.check_end_time = this.$timeTo.time4(this.checkTime[1]);
      } else {
        this.from.check_start_time = "";
        this.from.check_end_time = "";
      }
    },
    TimeValue: function (newVal, oldVal) {
      if (newVal != null) {
        this.from.start_time = this.$timeTo.time4(this.TimeValue[0]);
        this.from.end_time = this.$timeTo.time4(this.TimeValue[1]);
      } else {
        this.from.start_time = "";
        this.from.end_time = "";
      }
    },
  },
  methods: {
    crtChg(e) {
      this.from.page = e;
      this.getlist();
    },
    emitUser(id) {
      this.$confirm("是否真的要打款吗?, 是否打款?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(async () => {
          const { data } = await userRemit({ id });
          if (data.code != 200) {
            this.$message.error(data.data);
          } else {
            this.getlist();
            this.$message({
              type: "success",
              message: "打款成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async TXsuccess() {
      const { data } = await userCheck({ id: this.TXid, status: 2 });
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success(data.data);
      this.getlist();
      this.dialogShenHeShow = false;
    },
    async TXerror() {
      const { data } = await userCheck({ id: this.TXid, status: 3 });
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success(data.data);
      this.getlist();
      this.dialogShenHeShow = false;
    },
    async getlist() {
      const { data } = await userCapital(this.from);
      if (data.code != 200) return this.$message.error(data.data);
      this.list = data.data.user_capital_list.data;
      this.total = data.data.user_capital_list.total;
    },
    shaixuan() {
      this.from.page = 1;
      this.getlist();
    },
    shenhe(id, name) {
      this.TXid = id;
      this.TXname = name;
      this.dialogShenHeShow = true;
    },
  },
  created() {
    this.getlist();
  },
};
</script>

<style scoped lang='less'>
.box {
  .top {
    background-color: #f1f3f4;
    margin: 0 0 20px 0;
    border-radius: 5px;
    box-shadow: 4px 4px 3px #e9ebec;
    .el-input {
      width: 300px;
      margin: 10px;
      .el-input__inner {
        height: 32px;
      }
    }
    .addshop {
      padding: 5px 10px;
      color: #f1f1f1;
      border-radius: 3px;
      transition: font-size 0.2s;
    }
    .time {
      padding: 10px;
      display: inline-block;
    }
  }
  .shenhe {
    span {
      color: #555;
      font-size: 14px;
      &:last-child {
        border-bottom: 1px solid #555;
        &:hover {
          color: #111;
          border-bottom: 1px solid #111;
        }
      }
    }
  }
}
.dauan {
  color: #555;
  font-size: 14px;
  margin: 12px;
  &:hover {
    color: #111;
    border-bottom: 1px solid #111;
  }
}

.el-dialog {
  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .txbtn {
    margin-top: 50px;
    display: flex;
  }
}
</style>